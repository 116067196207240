a{cursor: pointer}
.btn {
  /*border-color: transparent;*/
  padding: 8px 15px ;
  transition: all 0.3s ease 0s;
  font-size: 1.4rem;
  @include respond(phone){
    font-size: 1.6rem; font-weight: bold;
  }
 /* &:active{background:url(/src/assets/images/ajax-loader-gcs.gif) no-repeat center center;  }*/
  &.btn-loading{position: relative;
    &:before{
      content:url('/src/assets/images/loader-small.gif'); position: absolute; top:10px; left:10px;
    }
  }
}
/*button[type=submit]:disabled{position: relative;
  &:before{
    content:url('/src/assets/images/loader-small.gif'); position: relative; top:3px; left:0; display: inline-block; padding:0 10px 0 0;
  }
}*/



.btn-disabled{background:$color-grey-light-4; opacity: .5}
.btn-default{ color: $color-white; background: $hays-azureblue;
  &:not(:last-child) {
    margin-right: 10px
  }
  &:link,
  &:visited,
  &:hover{
    background-color: $hays-azureblue-hover; color:$color-white;
    /*display: inline-block;
    text-decoration: none;
    position: relative;*/ cursor: pointer;
  }
}
.btn-primary { color: $color-white; background: $hays-azureblue; border-color:$hays-azureblue;
  &:not(:last-child) {
    margin-right: 10px
  }
  &:link,
  &:visited,
  &:hover{
    background-color: $hays-azureblue-hover;
    display: inline-block;
    text-decoration: none;
    position: relative;
  }


}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: $color-dark-blue;
  border-color: $color-dark-blue;
}
.btn-secondary { color: $hays-midinightblue; background:$hays-mint ; border: 0;
  &:not(:last-child) {
    margin-right: 10px
  }
  &:link,
  &:visited,
  &:hover{
    background-color: $hays-mint-hover;
    color: $hays-midinightblue;
    display: inline-block;
    text-decoration: none;
    position: relative;
  }
}

/*dark blue*/
/*.btn-default:hover, .btn-default:active:hover {
  background: $color-cyan
}*/


.btn-round{
  background: $color-pink;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  text-align: center;
  color: $color-white; border:0;
}


button.btn-text {
  border: 0;
  background: none;
  /*text-transform: uppercase;*/
  font-size: 1.4rem;
  font-weight: 500;
  color: $color-dark-blue;
 /* margin-bottom: 15px;*/
  padding: 10px 10px 10px 0; margin-right:20px;
}



.btn-sml {
  padding: 5px 20px !important; /*border:0;*/
  font-size: $font-size-14;
}

input {
  &:active {
    outline: none;
    border: none;
  }
  &:focus {
    outline: 0;
  }
}



.page-action-bar {
  padding: 15px 0;
  .btn {
 /*   min-width: 180px;*/
    @include respond(phone){
      width: 48%;
      min-width: 48%;
    }
  }
}

.btn-text, a {
  color: $hays-azureblue; cursor: pointer;
  &:link,
  &:visited {
    font-size: 1.4rem;
    color: $hays-azureblue;
    display: inline-block;
    text-decoration: none;
    transition: all .2s;
  }

  &:hover {
    color: $hays-azureblue-hover;

  }

  /*&:active {
    box-shadow: 0 1rem .5rem rgba($color-black, .15);
    transform: translate(0);
  }*/
}

  .error-input{border-color:$color-red;}

