//Primary Colors

$color-cyan: #009fda; //Hays Blue
$color-dark-blue:#002776; //hays Dark Blue
$color-orange:#f6851f; // Hays Orange
$color-white: #fff; // white
$color-black:#000; //black
$color-pink:#d64396; // hays Pink
$color-purple:#80379b; // Hays Purple
$color-red:#dc3545; // red
$color-green:#80a04b; // Hays Green

$color-grey-bdr:#E0E0E0; //border color


$grey-lightest:#f0f0f0;
$color-grey-light-4: #dee2e6;  //for border
$color-grey-light-2:#fafafa; //for card header


$color-text-grey: #575757;
$color-text-dark: #3f485a;
/* Hays new branding color */

/*Primary Colours*/

$hays-midinightblue: #0A0532;
$hays-midinightblue-hover: #50ADFF ;
$hays-midinightblue-light: #E8EEFF;
$hays-azureblue: #2173FF;
$hays-azureblue-hover: #0F42BE;
$hays-mint: #A0F2E4;
$hays-mint-hover: #C8FBE8;
$hays-parchment:#f5f0eb;

/*secondary_colours*/

$hays-teal:#06D6A0;
$hays-darkteal:#0B7A75;
$hays-orange:#F7911E;
$hays-yellow:#E9E346;
$hays-liac: #CFB6FF;
$hays-purple:#40008F;
$hays-glow-green:#2A9F1E;

/*Text Colour

text_midnight_blue

text_black

text_white

text_midnight_bluelight_1

text_midnight_bluelight_7
*/


//font-size

$default-font-size:1.6rem;
$font-size-12:1.2rem;
$font-size-11:1.1rem;
$font-size-14:1.4rem;

//font-Weight

$font-w-normal:normal !important;
$font-w-bold:bold !important;;

//font-style
$text-uppercase: uppercase;

//text-opacity
$text-opacity: .65;



//shadow

$shadow-full:0 1px 16px rgba(0,0,0,.20);

