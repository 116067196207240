header{    padding: 15px 15px 0; background: $color-white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
 /* margin: 0 -15px;*/
  position: relative;
  z-index: 1;
  @include respond(phone){

  }
  .logo{ padding-bottom: 16px;

    @include respond(tab-port){
      width:100%;
    }
    @include respond(phone){
      width: 125px; overflow: hidden;
    }
  }
  .custom-logo{
    padding-bottom:0;
    @include respond(phone){
      padding-bottom: 10px;
    }
    img{
     ///* height:50px;*/
      @include respond(phone){
        max-width: 100%;
      }
    }
  }

.search-mobile{
  display: none;
  @include respond(tab-port){
    display: inline-block;
    width: 37px;
    height: 26px;
    text-align: center;
    position: relative;
    top: 2px;
    margin-right: 15px;
    cursor: pointer;
    opacity: .3; right:0;
  }
  i{font-size:16px;}

}
.menu-mobile{
  display: none;
  @include respond(tab-port){
    display: inline-block;
    position: relative; top:6px;
  }
  em{
  @include respond(tab-port){
    display: inline-block;
    margin-right: 15px;
    font-style: normal;
    position: relative;
    top: -5px;
    font-size: 2rem;
    opacity: .5;
  }
    @include respond(phone){display: none;}
  }
  i{display: none;
    @include respond(tab-port){ display: inline-block; width:26px;}
    span{
      @include respond(tab-port){
        display: block;
        height: 3px;
        margin-bottom: 3px;
        background: #ccc;
        /*display: block; border-bottom: 2px solid #ddd; margin-bottom: 5px; width: 20px; */cursor: pointer;
      }
    }
  }

}
.home-mobile{
  display: none;
  @include respond(tab-port){
    display: block;
    margin-top:0px;
  }
}
.worldwide-mobile{
  display: none;
  @include respond(tab-port){
    display: block;
  }
}

.worldwide-yrij{
  display: none !important;
}

.navbar{padding:0;justify-content: normal;
  @include respond(tab-port){
    background: #fff;
    position: fixed;
    right: -260px;
    top: 0;
    z-index: 10001;
    width: 254px;
    height: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    overflow: auto; align-items: normal;
  }
  ul{
    display: flex; flex: auto;
    @include respond(tab-port){
      display: block; position: absolute; top:0; width:100%;
    }
    li{position: relative; padding-right:8px;
      @include respond(tab-port){
       padding-right: 0;
      }
      .nav-item{padding: 10px;
        font-size: 1.4rem;
        font-family: saans_hayssemibold; color:$hays-midinightblue ;

        &:hover{color: $hays-midinightblue-hover !important;}

        @include respond(tab-port){
          margin-top: 0; font-size: 1.8rem; color: $hays-midinightblue;
        }
        &:hover{
          color: $hays-azureblue-hover;
        }
        &.icon-sign-in{
          @include respond(tab-port){
            position:absolute;
            width:100%;
            top:-45px;
            &:before{
              content:'\e807'; @include icon-menu;
              top: 3px;
            }
          }
        }
        &.icon-salary-calc{
          &:before {
            @include respond(tab-port) {
              content: '\f1ec';
              @include icon-menu;
            }
          }
        }
        &.icon-home{
          &:before {
            @include respond(tab-port) {
              content: '\e81e';
              @include icon-menu;
            }
          }
        }
        &.icon-saved-jobs{
          &:before {
            @include respond(tab-port) {
              content: '\e81b';
              @include icon-menu;
            }
          }
        }
        &.icon-find-us{
          &:before {
            @include respond(tab-port) {
              content: '\f0f7';
              @include icon-menu;
            }
          }
        }
        &.icon-hays-connect{
          &:before {
            @include respond(tab-port) {
              content: '\e820';  @include icon-menu;
            }
          }
        }
        &.icon-customer-service{
          &:before {
            @include respond(tab-port) {
              content: '\e81d';
              @include icon-menu;
            }
          }
        }
        &.icon-worldwide{
          &:before {
            @include respond(tab-port) {
              content: '\e81c';
              @include icon-menu;
            }
          }
        }
        &.icon-sign-out{
          &:before {
            @include respond(tab-port) {
              content: '\e813';
              @include icon-menu;
            }
          }
        }
        &.icon-my-account{
          @include respond(tab-port) {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:before {
              content:'\e807'; @include icon-menu;
            }
          }
        }
        &.icon-my-searches{
          &:before {
            @include respond(tab-port) {
              content: '\e81a';
              @include icon-menu;
            }
          }
        }
        &.icon-my-learning{
          @include respond(tab-port) {
            position:relative;
          padding-left:40px;
          &:before {
              background: url(/assets/images/my-learning-icon.png) no-repeat;
              content: "";
              position: absolute;
              top:13px;
              left:5px;
              height:17px;
              width:26px;
            }
          }
        }
      }
      a{
        @include respond(tab-port){display: block;
          padding:5px; border-bottom: 1px solid $color-grey-light-4; font-size: 1.8rem;
        }
        &.useful-link{
          &:after{    content: '\e815';
            font-family: fontello;
            display: inline-block;
            width: 25px;
            font-size: 10px;
            font-weight: normal;
            padding-left: 6px;
            position: relative;
            top: -2px;}
          @include respond(tab-port){
            &:before{
              content: '\e81f'; @include icon-menu;
            }
        }

        }
      }
      &:first-child{
        @include respond(tab-port){
          margin-top: 45px;
        }
      /*  a{padding-left:0;}*/
      }
      &:last-child{position: absolute;
        right:0;
        padding-right: 0;
        @include respond(tab-port){
          left: 0;
          display: block; text-align: left!important;
        }
        a{padding-right:0; display: block;
        }


      }
      /*&:nth-child(2){
        position:absolute;
        right:-30px;
        @include respond(tab-port){
          right: auto;
          width: 100%;
          text-align: left !important;
        }
      }*/
      .sub-nav{
        position:absolute;
        top:43px;
        left:0;
        display:block;
        background:#fff;
        width:180px;
        border:1px solid #eee; z-index: 10;
        & > li{ padding-right:0; position:relative; left:0;
          border-bottom: 1px solid $color-grey-light-4;
          a{display:block; padding: 10px 15px 10px 12px; font-family: saans_hayssemibold; color: $color-dark-blue;
            @include respond(tab-port){
              padding-left: 32px;
            }
          }
          @include respond(tab-port){
            &:first-child{margin-top: 0;}
          }
        }
        @include respond(tab-port){
          position: relative; top:0; width: 100%;
        }
      }
      &.change-languages{position: absolute; top: -44px;
        right: 0px;
        .form-control{height:30px }
        @include respond(tab-port){position: relative;
          top: 14px;
          left: 14px;
          width: 88%;
        }
      }
      &.my-account-link{
        display:none;
        @include respond(tab-port){
          display: block;
          margin-top:0;
          text-align: left !important;
        }
      }
      &.log-in-out{
        position:absolute;
        right:0px;
        width: auto;
        padding-right: 0;
        @include respond(tab-port){
          text-align: left !important;
          right: auto;
          width: 100%;
          position: relative;
          margin-top:0;
        }
        a{
          &.nav-item{
            display: inline-block;
            @include respond(tab-port){
              position: relative;
              top: 0;
              display: flex;
              width: 100%;
              &:before{top:4px}
            }
          }
        }
      }

      //&.register{
      //  position:absolute;
      //  right:70px;
      //  @include respond(tab-port){
      //    text-align: left !important;
      //    right: auto;
      //    width: 100%;
      //  }
      //}
    }
  }

}
.alert-count{ position: absolute;
  right: 150px;
  bottom: 5px; font-family: saans_hayssemibold;

  @include respond(phone) {
    position: absolute;
    right: 4px;
    top: 4px;
    background: $color-cyan;
    width: 22px;
    height: 22px;
    border-radius: 10px;
  }
  a {
    color: $color-red;
    @include respond(phone) {color:$color-white;}
  }
  .text-alert{
   display: inline-block;
    @include respond(phone){
      display: none;
    }
  }
  .text-count{ margin-right: 5px;
    @include respond(phone){
      position: relative; top:1px;
    }
  }
}

.page-header{padding:15px;}
  .tech-logo{
    @include respond(phone){
      width:220px;
      padding-bottom: 10px;
      img{
        width: 210px;
      }
    }
  }
}


@media(max-width:767px){
  header .navbar ul ul.headerSection {
    position: static;
  }
  header .navbar ul ul.headerSection li {
    margin: 0;
  }
}
